import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IChangePassword } from "pages/auth/change-password";
import { appApi } from "store/apis/apiSlice";
import {
  IUserFullDetails,
  ILoginResponseInterface,
  ILogin,
  INotificationToken,
  IGetUsers,
} from "types";
import { IProfileUpdate } from "types/profile";

const authApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["Auth", "USER-PROFILE"],
});

export const authApi = authApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<ILoginResponseInterface, ILogin>({
      query: ({ emailOrUsername, password }) => {
        localStorage.clear();
        return {
          url: "/login",
          method: "POST",
          body: {
            emailOrUsername,
            password,
          },
        };
      },
      invalidatesTags: ["Auth", "USER-PROFILE"],
      transformErrorResponse: (error: any) => {
        return error;
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query: () => {
        return {
          url: "/logout",
          method: "POST",
        };
      },

      invalidatesTags: ["Auth", "USER-PROFILE"],
    }),
    getUser: builder.query<IUserFullDetails, undefined | void>({
      query: () => "profile",
      providesTags: ["Auth", "USER-PROFILE"],
      transformResponse: (res: { user: [IUserFullDetails] }) => {
        const user = res.user[0];
        return user;
      },
    }),
    updateUser: builder.mutation<any, IProfileUpdate>({
      query: (data: IProfileUpdate) => {
        return {
          url: "profile",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["USER-PROFILE"],
      transformErrorResponse: (err) => {
        return err;
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ user_name }) => ({
        url: "forgot-password",
        method: "POST",
        accept: "application/json",
        body: { user_name },
      }),
      transformErrorResponse: (err) => {
        return (err.data as { errors: { user_name: [string] } })?.errors
          ?.user_name[0];
      },
    }),
    changePassword: builder.mutation<void, IChangePassword>({
      query: (body) => ({
        url: "change-password",
        accept: "application/json",
        method: "POST",
        body,
      }),
      transformErrorResponse: (err: FetchBaseQueryError) => {
        if (err.data) {
          return err.data;
        }
        return err;
      },
    }),
    changeProfileImage: builder.mutation<
      {
        data: string;
        message: string;
      },
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: `/profile-image`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["USER-PROFILE"],
    }),
    notificationToken: builder.mutation<
      ILoginResponseInterface,
      INotificationToken
    >({
      query: ({ id, token }) => {
        localStorage.clear();
        return {
          url: "/notification-token",
          method: "POST",
          body: {
            id,
            token,
          },
        };
      },
      invalidatesTags: ["Auth", "USER-PROFILE"],
      transformErrorResponse: (error: any) => {
        return error;
      },
    }),

    switchToPACDASH: builder.query<
      { url: string | undefined },
      string | undefined
    >({
      query: () => ({
        url: "/getPACDASHUrl",
        method: "GET",
      }),
      transformResponse: (res: { url: string }) => {
        // console.log('PACDash Response:', res); // Debugging statement
        return res;
      },
      transformErrorResponse: (error: any) => {
        // console.error('PACDash Error:', error); // Debugging statement
        return error;
      },
    }),

    switchToMindscan: builder.query<
      { url: string | undefined },
      string | undefined
    >({
      query: () => ({
        url: "/getMindscanUrl",
        method: "GET",
      }),
      transformResponse: (res: { url: string }) => res,
    }),

    getMultipleUser: builder.query({
      query: () => ({
        url: "/getUsers",
        method: "GET",
      }),
      transformResponse: (res: any) => res,
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useChangeProfileImageMutation,
  useNotificationTokenMutation,
  useLazySwitchToPACDASHQuery,
  useLazySwitchToMindscanQuery,
  useGetMultipleUserQuery,
} = authApi;
